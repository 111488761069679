import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import WhoAreWe from "../../components/who-are-we"
import SEO from "../../components/seo"
import helpers from "../../scripts/helpers"

class BoschBSH extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-bsh inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">BOSCH B/S/H</h2>
            </div>

            <article className="article project inuit-u-p0">

                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="http://comotionapp.com/" title="See CoMotion!" target="_blank">
                                <img src="/img/clients/Bosch_SL-en.png" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">BOSCH</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="http://comotionapp.com/" title="See CoMotion!" target="_blank">bosch-home.ma</a>
                            </span>
                            <span className="project__detail">Année 2016</span>
                        </p>



                        <ul className="article__share-list">
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--facebook" target="blank" title="Share on Facebook"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--twitter" target="blank" title="Share on Twitter"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--linkedin" target="blank" title="Share on Linkedin"></a>
                            </li>

                            <li className="article__share-item">
                                <a href="#" onClick={this.openPopUp}
                                    className="article__share-link article__share-link--gplus" target="blank" title="Share on Google Plus"></a>
                            </li>
                        </ul>
                    </aside>

                    <h1 className="article__heading">BOSCH Siemens Hausgeräte B/S/H.</h1>
                    <p className="article__para">
                        Bosch Electroménager appartient au groupe BSH Hausgeräte GmbH dont la filiale au Maroc est BSH
                        Electroménagers SA. Numéro 3 mondial de l'électroménager, BSH propose au Maroc la marque Bosch.<br />
                        Innovation en matière d’efficacité énergétiqueLE groupe Bosch et Siemens électroménager (BSH) renforce sa
                        présence au Maroc à travers sa filiale «BSH Electroménagers SA» sise à Casablanca. Un réseau de service
                        après-vente couvre d’ores et déjà les grandes villes du Royaume. «BSH entend jouer un rôle de premier plan
                        sur un marché arrivé à maturité, tant par l’évolution du niveau de vie des ménages marocains que par le
                        dynamisme du secteur de la distribution et de la construction», selon les responsables de la firme.

                    </p>
                    <p className="article__para">
                        <span className="project__detail project__detail--small">Conception, Digital, Vidéos, Prints, Stands et Achat
                            Media</span>
                    </p>
                </div>
                <h1 className="article__heading">Conception Riche Media & Campagnes Display</h1>

                <p className="parallax parallax--horizontal article__belt">
                    <img className="article__belt-image parallax__bg" src="/img/projects/bosch_maroc_bsh/pack_digital.png" alt="Campagne Digitale Ramadan 2016" />
                    <img className="article__belt-image parallax__layer parallax__layer--bottom parallax__layer--1" src="/img/projects/bosch_maroc_bsh/web-design-second-second.png"
                        alt="web-design" />
                    <img className="article__belt-image parallax__layer parallax__layer--bottom parallax__layer--2" src="/img/projects/bosch_maroc_bsh/web-design-first-second.png"
                        alt="web-design" />
                </p>

                <h1 className="article__heading">SEA : Campagnes Search</h1>

                <p className="parallax parallax--horizontal article__belt">
                    <img className="article__belt-image parallax__bg" src="/img/projects/bosch_maroc_bsh/campagnesearch.jpg"
                        alt="Campagne Digitale Ramadan 2016" />
                    <img className="article__belt-image parallax__layer parallax__layer--bottom parallax__layer--1" src="/img/projects/bosch_maroc_bsh/google-search.png"
                        alt="web-design" />
                    <img className="article__belt-image parallax__layer parallax__layer--bottom parallax__layer--2" src="/img/projects/bosch_maroc_bsh/mobile-google-search.png"
                        alt="web-design" />
                </p>

                <h1 className="article__heading">Postproduction, Modifications et Retouches Videos</h1>
                <p className="article__para">
                    <span className="project__detail project__detail--small">Aspirateur sans fil Athlet Bosch | Bosch</span>
                </p>
                <p className="article__belt inuit-u-m0">
                    <video className="article__video article__video--play-on-scroll" autoPlay loop muted>
                        <source src="/video/bosch-video-1.mov" type="video/mp4" />
                    </video>
                </p>

                <p className="article__para">
                    <span className="project__detail project__detail--small">Kitchen machine MaxxiMUM | BOSCH</span>
                </p>

                <p className="article__belt inuit-u-m0">
                    <video className="article__video article__video--play-on-scroll" autoPlay loop muted>
                        <source src="/video/bosch-video-2.mp4" type="video/mp4" />
                    </video>
                </p>

                <div className="article__body">
                    <h2 className="article__heading">Goodies et Impressions</h2>
                    <p className="article__para">

                    </p>
                </div>

                <p data-parallax-speed="0.20" className="parallax parallax--horizontal article__belt inuit-u-mb0">
                    <img className="article__belt-image parallax__bg" src="/img/projects/bosch_maroc_bsh/print-background.png"
                        alt="Campagne Digitale Ramadan 2016" />
                    <img className="article__belt-image parallax__layer parallax__layer--1" src="/img/projects/bosch_maroc_bsh/goodies_polos.png"
                        alt="Polo Braindé Bosch" />
                    <img className="article__belt-image parallax__layer parallax__layer--2" src="/img/projects/bosch_maroc_bsh/goodies_stylos.png"
                        alt="Stylo Braindé Bosch" />
                </p>
                
            </article>

            <WhoAreWe />
        </Layout>
    
  )

  openPopUp = () => {
    window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600')
    return false
  }

  componentDidMount = () => {
    helpers.runParallaxEffect()
  }


}

export default BoschBSH
